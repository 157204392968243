





































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class ResursBankConfig extends Vue {
  username = null
  password = null
  status = true
  passwordVisibility = true

  mounted() {
    this.$axios.get('/v3/resurs_bank/configuration').then((response) => {
      const configuration = response.data.data.configuration
      this.username = configuration.username
      this.password = configuration.password
      this.status = configuration.status
    })
  }

  update(e) {
    e.preventDefault()

    const data = {
      username: this.username,
      password: this.password,
      status: this.status,
    }

    this.$axios.post('/v3/resurs_bank/update', data).then((response) => {
      vxm.alert.success({
        content: this.$t('resurs_bank.alert_configuration_updated') as string,
        title: this.$t('c:common:Success') as string,
      })
    })
  }

  validate(e) {
    const data = {
      username: this.username,
      password: this.password,
    }

    this.$axios.post('/v3/resurs_bank/validate', data).then((response) => {
      vxm.alert.success({
        content: this.$t('resurs_bank.alert_configuration_validated') as string,
        title: this.$t('c:common:Success') as string,
      })
    })
  }
}
